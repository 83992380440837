import { useEffect, useState } from "react"
import AepsOnBording from "../../../components/admin/aepsOnboarding/AepsOnBoarding"
import { UserPermissionAeps, aepsOnboard,UserPermissionAepswithuiqueid  } from "../../../api/login/Login"
import PermissionAepsOnboarding from "../../../components/admin/aepsOnboarding/permissionAepsOnboarding/PermissionAepsOnboarding"
import SuggestOnboarding from "../../../components/admin/aepsOnboarding/suggetsOnboarding/SuggestOnboarding"
import { ToastContainer, toast } from "react-toastify"
import BankPermission from "../../../components/admin/aepsOnboarding/bankPermission/BankPermission"
import AuthResister from "../../../components/admin/aepsOnboarding/authResister/AuthResister"
import DailyAuth from "../../../components/admin/aepsOnboarding/dailyAuth/DailyAuth"
import CompleteOnBoarding from "../../../components/admin/aepsOnboarding/comleteOnBoarding/CompleteOnBoarding"
import Loader from "../../../common/loader/Loader"
import Bank4aepsverify from "./Bank4aepsverify"
import Instanpayonboarding from "../../../components/admin/aepsOnboarding/suggetsOnboarding/Instanpayonboarding"
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


function AepsOnBoardingPage({walletShowHeader,profiledata}) {
    console.log("profiledata",profiledata)

    const [loder, setLoder] = useState(false)

    const [isPlanPurchage, SetisPlanPurchage] = useState(false)


    const [onBoading, setOnBoarding] = useState(false)
    const [activeMessage, setActiveMessage] = useState()
    const [buyMessage, setBuyMessage] = useState()

    const [bank2, setbank2] = useState()
    const [bank3, setbank3] = useState()
    const [bankverify, setbankverify] = useState(false)

    const [suggestOnBoard, setSuggestOnBoarding] = useState(false)

    const [bankPermission, setBankPermission] = useState(false)
    const [authRegistered, setAuthRegistered] = useState(false)
    const [dailyAuth, setDailyAuth] = useState(false)
    const [balanceEnquiryPermission, setBalanceEnquiryPermission] = useState(false);
const [cashWithdrawalPermission, setCashWithdrawalPermission] = useState(false);
const [miniStatementPermission, setMiniStatementPermission] = useState(false);
const [aadhaarPayPermission, setAadhaarPayPermission] = useState(false);
const [dailyAuthCompleted, setDailyAuthCompleted] = useState(false);
const [bankServerDown, setBankServerDown] = useState(false);
const [isInstantpayOnBoarded, setIsInstantpayOnBoarded] = useState(null)
const [merchnadecode, setMerchantCode] = useState(null)
const [openInstantpayOnBoarded, setOpenIsInstantpayOnBoarded] = useState(false);
const [showContactAdmin, setShowContactAdmin] = useState(false)
const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "error" });
const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = (message, severity = "error") => {
    setSnackbar({ open: true, message, severity });
  };
const permissionAeps = async () => {
    setLoder(true);
    try {
        const res = await UserPermissionAeps();
        const cashWithdrawalRes = await UserPermissionAepswithuiqueid(207);
        const aadhaarPayRes = await UserPermissionAepswithuiqueid(208);
        let conditionMet = false
        console.log("resposne",res)
        if ('isInstantpayOnBoarded' in res?.data?.data) {
            console.log("isInstantpayOnBoarded found in response");
            setIsInstantpayOnBoarded(res.data.data.isInstantpayOnBoarded);
            setMerchantCode(res.data.data.merchantCode);
            
            if (res.data.data.isInstantpayOnBoarded === false) {
                console.log("User is not onboarded with Instantpay");
                setOnBoarding(true)
                setSuggestOnBoarding(true)
                setBankPermission(true)
                setAuthRegistered(true)
                setDailyAuth(true)
                setbankverify(false);
                setOpenIsInstantpayOnBoarded(true)
                conditionMet = true
              
            } else if (res.data.data.isInstantpayOnBoarded === true && res.data.data.authRegistered === true) {
                console.log("User is onboarded with Instantpay and auth registered");
                setOnBoarding(true)
            setSuggestOnBoarding(true)
            setBankPermission(true)
            setAuthRegistered(true)
            setDailyAuth(true)
            setbankverify(false);
            conditionMet = true
            }
            else if (
                res?.data?.statusCode == '007' ||
                cashWithdrawalRes?.data?.statusCode == '007'||
                // miniStatementRes?.data?.statusCode == '007' ||
                aadhaarPayRes?.data?.statusCode == '007' 
            ) {
                setbankverify(true);  
                conditionMet = true
            }
            else if (res?.data?.statusCode == '00127') {
                setBankServerDown(true);
                conditionMet = true
                setLoder(false);
                return; // Exit the function early
            }
        } 

        if (
            res?.data?.statusCode == '007' ||
            cashWithdrawalRes?.data?.statusCode == '007'||
            // miniStatementRes?.data?.statusCode == '007' ||
            aadhaarPayRes?.data?.statusCode == '007' 
        ) {
            setbankverify(true);  
            conditionMet = true
        }
        if (res?.data?.statusCode == '00127') {
            setBankServerDown(true);
            conditionMet = true
            setLoder(false);
            return; // Exit the function early
        }

        if (res?.data?.data?.is_active == false) {
            setActiveMessage('Please Admin Side Active User')
            setbankverify(false);
            conditionMet = true

        }
        if (res?.data?.data?.is_active == true) {
            if (res?.data?.data?.is_buy == false) {
                setBuyMessage('Please Purchase');
                setbankverify(false);
                conditionMet = true
            }
        }


        setBalanceEnquiryPermission(res?.data?.data?.is_active && res?.data?.data?.is_buy);
        setCashWithdrawalPermission(cashWithdrawalRes?.data?.data?.is_active && cashWithdrawalRes?.data?.data?.is_buy);
        setMiniStatementPermission(res?.data?.data?.is_active && res?.data?.data?.is_buy);
        setAadhaarPayPermission(aadhaarPayRes?.data?.data?.is_active && aadhaarPayRes?.data?.data?.is_buy);
//             console.log('Cash Withdrawal API Response:', cashWithdrawalRes);
// console.log('Aadhaar Pay API Response:', aadhaarPayRes);
// console.log('balanceenquiry API Response:', balanceEnquiryRes);
// console.log('miniStatementRe API Response:', miniStatementRes);
//         console.log('Cash Withdrawal API Responsedata:', cashWithdrawalRes?.data?.data);
// console.log('Aadhaar Pay API Response data:', aadhaarPayRes?.data?.data);
// console.log('balanceEnquiryRes API Responsedata:', balanceEnquiryRes?.data?.data);
// console.log('miniStatementRe API Responsedata:', aadhaarPayRes?.data?.data);


        if (res?.data?.data?.is_active == true && res?.data?.data?.is_buy == true) {
            SetisPlanPurchage(true)
            setOnBoarding(true)
            setSuggestOnBoarding(false)
            setBankPermission(true)
            setAuthRegistered(true)
            setDailyAuth(true)
            setbankverify(false);
            conditionMet = true
        }

        if (res?.data?.data?.isOnBoarded == true) {
            setOnBoarding(true)
            setSuggestOnBoarding(true)
            setBankPermission(false)
            setAuthRegistered(true)
            setDailyAuth(true)
            setbankverify(false);
            conditionMet = true
        }

        if (res?.data?.data?.bank2 == true || res?.data?.data?.bank3 == true ) {
            setOnBoarding(true)
            setSuggestOnBoarding(true)
            setBankPermission(true)
            setAuthRegistered(false)
            setDailyAuth(true)
            setbankverify(false);
            conditionMet = true
        }

        if (res?.data?.data?.authRegistered == true) {
            setOnBoarding(true)
            setSuggestOnBoarding(true)
            setBankPermission(true)
            setAuthRegistered(true)
            setDailyAuth(false)
            setbankverify(false);
            conditionMet = true
        }


        if (res?.data?.data?.dailyAuth == true) {
            setOnBoarding(true)
            setSuggestOnBoarding(true)
            setBankPermission(true)
            setAuthRegistered(true)
            setDailyAuth(true)
            setbankverify(false);
            conditionMet = true

        }



        // if (res?.data?.data?.isOnBoarded == true) {
        //     setOnBoarding(false)

        // }


        if (!conditionMet) {
            setShowContactAdmin(true)
        }






        setLoder(false)
    } catch (error) {
        setLoder(false)
    }
}
    const handleDailyAuthCompletion = () => {
        setDailyAuthCompleted(true);
        setDailyAuth(true);  
        permissionAeps();  
      }
    const toastSuccessMessage = (str) => {
        toast.error(`${str}`, {
            position: "top-center",
            autoClose: 2000,
        });
    };

    const onBoardingApiCall = async () => {
        const data = { user_id: window.localStorage.getItem('userIdToken') }
        try {
            const res = await aepsOnboard(data)
            // console.log(res);
            if (res?.data?.statusCode == '403') {
                showSnackbar(res?.data?.message || "Something Went Wrong", "error");
            }
            if (res?.data?.statusCode == 200) {
                window.open(res?.data?.data?.url, '_blank')
                showSnackbar(res?.data?.message || "Succesfull",);
            }
        } catch (error) {
            showSnackbar("An error occurred during onboarding", "error");
        }
    }


    useEffect(() => {
        permissionAeps()
    }, [])
   
    return (
        <>
        {loder && <Loader />}

        {showContactAdmin ? (
             <div style={{
                textAlign: 'center',
                padding: '20px',
                backgroundColor: '#f0f0f0',
                borderRadius: '5px',
                margin: '20px auto',
               
            }}>
                <p>We're unable to process your request at this time. Please contact the administrator at <strong>1800 889 0178</strong> for assistance.</p>
            </div>
        ) : (
            <>
                {!isPlanPurchage ? (
                    <PermissionAepsOnboarding activeMessage={activeMessage} buyMessage={buyMessage} />
                ) : (
                    <>
                        {suggestOnBoard ? '' : <SuggestOnboarding onBoardingApiCall={onBoardingApiCall} isInstantpayOnBoarded={isInstantpayOnBoarded} merchnadecode={merchnadecode} setbankverify={setbankverify}  permissionAeps={permissionAeps}/>}

                        {bankPermission ? '' : <BankPermission bank2={bank2} bank3={bank3} profiledata={profiledata} onDailyAuthComplete={handleDailyAuthCompletion} />}

                        {authRegistered ? '' : <AuthResister />}

                        {dailyAuth || dailyAuthCompleted ? '' : (
                            <DailyAuth onDailyAuthComplete={handleDailyAuthCompletion} />
                        )}
                        
                        {(suggestOnBoard && bankPermission && authRegistered && (dailyAuth || dailyAuthCompleted)) ? (
                            <CompleteOnBoarding
                                balanceEnquiryPermission={balanceEnquiryPermission}
                                cashWithdrawalPermission={cashWithdrawalPermission}
                                miniStatementPermission={miniStatementPermission}
                                aadhaarPayPermission={aadhaarPayPermission}
                                walletShowHeader={walletShowHeader}
                                isInstantpayOnBoarded={isInstantpayOnBoarded}
                            />
                        ) : ''}
                    </>
                )}

                {bankServerDown && (
                    <div style={{ 
                        textAlign: 'center', 
                        padding: '20px', 
                        backgroundColor: '#ffcccc', 
                        borderRadius: '5px',
                        margin: '20px auto',
                        maxWidth: '400px'
                    }}>
                        <h2 style={{ color: '#cc0000' }}>Bank Server is Down</h2>
                        <p>We apologize for the inconvenience. Please try again later.</p>
                    </div>
                )}
            </>
        )}

        <Bank4aepsverify
            show={bankverify}
            onHide={() => setbankverify(false)} 
            permissionAeps={permissionAeps}
        />

<Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
    )
}
export default AepsOnBoardingPage