import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Table, Col, Row, Card, Badge } from 'react-bootstrap';
import { Pagination } from 'antd';
import { FaEye, FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Loader from '../../../common/loader/Loader';
import { getDownstreamList, fundtransferToRefer, confirmReverseTransfer, Getprofile, getDownstreamListReport } from '../../../api/login/Login';
import "./Userlist.css";
import { WalletsShow } from '../../../api/login/Login';
import { Popconfirm } from 'antd';
import axios from 'axios';
import { baseUrl } from '../../../baseUrl';
import OtpVerificationPage from './UserlistOtp';

const Userlistzsm = ({walletShowHeader}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(10);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [expandedUserId, setExpandedUserId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [transferAmount, setTransferAmount] = useState('');
    const [walletData, setWalletData] = useState({});
    const [filterData, setFilterData] = useState({
        userId: '',
        name: '',
        email: '',
        mobile: '',
        is_approved: '',
        refer_id: ''
    });

    // Additional States
    const [isTransferValid, setIsTransferValid] = useState(false);
    const [showTpinModal, setShowTpinModal] = useState(false);
    const [showReverseTpinModal, setShowReverseTpinModal] = useState(false);
    const [tpin, setTpin] = useState('');
    const [tpinError, setTpinError] = useState('');
    const [showReverseTransferModal, setShowReverseTransferModal] = useState(false);
    const [reverseTransferAmount, setReverseTransferAmount] = useState('');
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [timeLeft, setTimeLeft] = useState(120);
    const [isTimerEnded, setIsTimerEnded] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [otpMessage, setOtpMessage] = useState('');
    const [storetoken, setStoreToken] = useState();
    const [maskedTpin, setMaskedTpin] = useState('');
    const [walletlockdata, setLockWAletData] = useState({});
    const [isPopconfirmOpen, setIsPopconfirmOpen] = useState(false);
    const [subUsersPage, setSubUsersPage] = useState(1);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [locationError, setLocationError] = useState(null);

    const navigate = useNavigate();
    const subUsersPerPage = 10;
    const length1 = 6;
    const [otp1, setOtp1] = useState(new Array(length1).fill(''));
    const inputs1 = useRef([]);

    // User Type Management
    const storedUserType = localStorage.getItem('userType');
    const storedUserTypeId = localStorage.getItem('userTypeId');
    const userToken = localStorage.getItem('userToken');

    // Hierarchy Helper Functions
    const getNextLevel = (currentLevel) => {
        const hierarchy = ['ZSM', 'ASM', 'Super Distributor', 'Distributor', 'Retailer'];
        const currentIndex = hierarchy.indexOf(currentLevel);
        return currentIndex < hierarchy.length - 1 ? hierarchy[currentIndex + 1] : null;
    };

    const getColumnTitle = () => {
        switch (storedUserType) {
            case 'ZSM':
                return 'ASM List';
            case 'ASM':
                return 'Super Distributor List';
            case 'Super Distributor':
                return 'Distributor List';
            case 'Distributor':
                return 'Retailer List';
            default:
                return 'User List';
        }
    };

    // Location Functions
    const getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLatitude(position.coords.latitude);
                        setLongitude(position.coords.longitude);
                        setLocationError(null);
                        resolve(true);
                    },
                    (error) => {
                        setLocationError("Unable to retrieve your location");
                        reject(error);
                    }
                );
            } else {
                setLocationError("Geolocation is not supported by your browser");
                reject(new Error("Geolocation not supported"));
            }
        });
    };

    // Data Fetching
    const fetchData = async (resetFilters = false) => {
        setLoading(true);
        try {
            let response;
            if (storedUserType) {
                response = await getDownstreamList(currentPage - 1, count, storedUserTypeId, filterData);
            } else {
                response = await getDownstreamList(currentPage - 1, count, '', filterData);
            }
            setData(response?.data?.data || []);
            setTotalCount(response?.data?.totalCount || 0);
        } catch (error) {
            console.error('Error fetching users:', error);
            toast.error('Failed to fetch users');
            setData([]);
            setTotalCount(0);
        }
        setLoading(false);
    };

    const walletShow = async () => {
        try {
            const res = await WalletsShow();
            setWalletData(res?.data?.data);
        } catch (error) {
            console.error('Error fetching wallet data:', error);
        }
    };

    const getDataProfile = async () => {
        try {
            const res = await Getprofile();
            setLockWAletData(res?.data?.data);
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    // Event Handlers
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSubUsersPageChange = (newPage) => {
        setSubUsersPage(newPage);
    };

    const handleTransferClick = (user) => {
        setSelectedUser(user);
        setShowTransferModal(true);
    };

    const handleUserClick = async (userId) => {
        if (expandedUserId === userId) {
            setExpandedUserId(null);
        } else {
            setExpandedUserId(userId);
            setLoading(true);
            try {
                const response = await getDownstreamList(page, count, userId);
                const updatedData = data.map(user =>
                    user._id === userId ? { ...user, subUsers: response?.data?.data || [] } : user
                );
                setData(updatedData);
            } catch (error) {
                console.error('Error fetching sub-users:', error);
                toast.error('Failed to fetch sub-users');
            }
            setLoading(false);
        }
    };

    const handleTransferAmountChange = (e) => {
        const amount = e.target.value;
        setTransferAmount(amount);
        setIsTransferValid(
            Number(amount) >= 0 &&
            Number(amount) <= Number(walletData?.main_wallet - walletlockdata?.locking_amt)
        );
    };

    const handleReverseTransferClick = (user) => {
        setSelectedUser(user);
        setShowReverseTransferModal(true);
    };
    const handleTransferSubmit = async () => {
        try {
            await getCurrentLocation();

            if (locationError) {
                toast.error("Location access is required for this transaction");
                return;
            }

            const response = await fundtransferToRefer({
                transferTo: selectedUser._id,
                amount: Number(transferAmount),
                tpin: tpin,
                trans_type: "transfer",
                lat: latitude,
                long: longitude
            });

            if (response.data.statusCode === "200") {
                toast.success('Money transferred successfully');
                setShowTpinModal(false);
                resetModalStates();
                fetchData();
                walletShow();
                if (walletShowHeader) walletShowHeader();
            } else {
                setTpinError(response?.data?.message);
                toast.error(response?.data?.message || 'Transfer failed');
            }
        } catch (error) {
            console.error('Error in transfer:', error);
            toast.error('Transfer failed. Please try again.');
        }
    };

    const handleReverseTransferSubmit = async () => {
        try {
            await getCurrentLocation();

            if (locationError) {
                toast.error("Location access is required");
                return;
            }

            const response = await fundtransferToRefer({
                transferTo: selectedUser._id,
                amount: Number(reverseTransferAmount),
                tpin: tpin,
                trans_type: "refund",
                lat: latitude,
                long: longitude
            });

            if (response.data.statusCode === "200") {
                const token = response.data.data.token;
                setStoreToken(token);
                setShowReverseTpinModal(false);
                setShowOtpModal(true);
            } else {
                setTpinError(response?.data?.message);
                toast.error(response?.data?.message);
            }
        } catch (error) {
            console.error('Error in reverse transfer:', error);
            toast.error('Reverse transfer failed');
        }
    };

    // Reset Functions
    const resetModalStates = () => {
        setTransferAmount('');
        setReverseTransferAmount('');
        setTpin('');
        setMaskedTpin('');
        setTpinError('');
        setOtp1(new Array(length1).fill(''));
        setOtpError('');
        setOtpMessage('');
        setIsTransferValid(false);
        setIsPopconfirmOpen(false);
    };

    // Render Functions
    const renderTable = () => {
        return (
            <Table className="table-hover" id="dataTable">
                <thead className="bg-light">
                    <tr>
                        <th>S.No</th>
                        <th>Refer ID</th>
                        <th>Name</th>
                        <th>Approved</th>
                        {storedUserType !== 'Retailer' && <th>{getNextLevel(storedUserType)} List</th>}
                        <th>Transfer</th>
                        <th>Reverse Transfer</th>
                        <th>Wallet Balance</th>
                        <th>Report</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? data.map((user, index) => (
                        <React.Fragment key={user._id}>
                            <tr>
                                <td>{index + 1 + (page * count)}</td>
                                <td>{user.refer_id}</td>
                                <td>{user.name}</td>
                                <td className={user.is_approved ? 'approvedstatusindownstrip' : 'pendingstatusindownstrip'}>
                                    {user.is_approved ? 'Approved' : 'Pending'}
                                </td>
                                {storedUserType !== 'Retailer' && (
                                    <td>
                                        <Button
                                            variant="link"
                                            onClick={() => handleUserClick(user._id)}
                                            className="d-flex align-items-center"
                                        >
                                            {getNextLevel(storedUserType)}s
                                            {expandedUserId === user._id ? <FaChevronUp className="ms-1" /> : <FaChevronDown className="ms-1" />}
                                        </Button>
                                    </td>
                                )}
                                <td>
                                    <Button 
                                        variant="outline-primary" 
                                        size="sm" 
                                        onClick={() => handleTransferClick(user)}
                                    >
                                        Transfer Money
                                    </Button>
                                </td>
                                <td>
                                    <Button 
                                        variant="outline-secondary" 
                                        size="sm" 
                                        onClick={() => handleReverseTransferClick(user)}
                                    >
                                        Reverse Transfer
                                    </Button>
                                </td>
                                <td>{user?.main_wallet || 0}</td>
                                <td>
                                    <Button 
                                        variant="link" 
                                        // onClick={() => handleReportClick(user._id)}
                                    >
                                        <FaEye className="text-primary" />
                                    </Button>
                                </td>
                            </tr>
                            {/* {expandedUserId === user._id && renderSubUsersTable(user)} */}
                        </React.Fragment>
                    )) : (
                        <tr>
                            <td colSpan="9" className="text-center">No data available</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    };
  return (
    <div>
      <h1>ZSMAsm</h1>
    </div>
  )
}

export default Userlistzsm
