import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';
import { Grid, Paper, Typography, Box, TextField, useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles'; 
import { 
  Payment, CreditCard, ElectricBolt, LocalGasStation, PhoneAndroid, 
  Tv, Wifi, LocalShipping, School, LocalHospital, Build
} from '@mui/icons-material';

// Professional color palette
const colors = {
  primary: '#1976d2',
  background: '#f5f7fa',
  cardBg: '#ffffff',
  text: '#333333',
  iconBg: '#e3f2fd'
};

const ServiceItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: colors.cardBg,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  borderRadius: '8px',
  border: '1px solid #e0e0e0',
  height: '100%',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: colors.iconBg,
  borderRadius: '50%',
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  [theme.breakpoints.up('sm')]: {
    width: '56px',
    height: '56px',
  },
}));

function ServiceGrid({ services,isInstantpaypresnt}) {

  const navigate = useNavigate();
  const [loadedImages, setLoadedImages] = useState({});
  const [searchTerm, setSearchTerm] = useState(''); // New state for search term
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const getIcon = (serviceName) => {
    const iconMap = {
      "Aeps Cash Deposit": Payment,
      "Credit Card": CreditCard,
      "Electricity": ElectricBolt,
      "DMT": Payment,
      "Gas": LocalGasStation,
      "Landline Postpaid": PhoneAndroid,
      "Fastag": LocalShipping,
      "DTH": Tv,
      "Broadband Postpaid": Wifi,
      "Water": Build,
      "Mobile Postpaid": PhoneAndroid,
      "Recharge": PhoneAndroid,
      "Aeps Bank Withdraw": Payment,
      "Aeps Adhaar pay": Payment,
      "CMS": Build,
      "Education Fees": School,
      "Quick Dhan": Payment,
      "NCMC Recharge": Payment,
      "Hospital and Pathology": LocalHospital,
      "Insurance": Build,
      "LPG Gas": LocalGasStation,
    };

    const Icon = iconMap[serviceName] || Build;
    return <Icon style={{ color: colors.primary, fontSize: isXs ? 20 : 24 }} />;
  };

  const routeMap = {
    "206": "/aeps-cash-deposit",
    "19": "/ccbill_payment_report",
    "18": "/electricity-bills",
    "33": "/mobile-reacharge",
    "13": "/dth-reacharge",
    "11": "/gas-bills",
    "10": "/postpaid-recharge",
    "5": "/Boardband",
    "35": "/airtel-cms",
    "12": "/landline-bills",
    "208": "/aeps-OnBoarded",
    "207": "/aeps-OnBoarded",
    "9": "/fastag-recharge",
    "188": "/money-transfer",
    "28": "/water-bills",
    "15": "/hospital-pathology",
    "3": "/educational-fees",
    "16":"/lpg-gasbill-payment",   
    "31":"/nmrc-paymentbill",   
    "36":"/quickdhan-payment",  
    "8":"/insurance-payment",  
    "17":"/muncipaltax-paymentbill",
    "29": "/metro-recharge",
  "2": "/loan-repayment", // Note: This is duplicate of "29", you might want to resolve this
  "22": "/life-insurance",
  "4": "/housing-society",
  "14": "/health-insurance-bill", // This seems to be duplicate of "15", you might want to resolve this
  "26": "/health-insurance",
  "30": "/clubs-associations",
  "21": "/cable-tv",
  "24": "/b2b",
  "6": "/subscription",
  "25": "/rent-bill",
  "216": "/recurringdeposite",
  "7": "/muncipaltax-paymentbill",
  "1": "/hospital-pathology", // A
  "27": "/donation", // A
  };

  const handleNavigation = (serviceId) => {
    const route = routeMap[serviceId] || "/Dashboard";
    navigate(route);
  };

  const handleImageLoad = (serviceId) => {
    setTimeout(() => {
      setLoadedImages(prev => ({ ...prev, [serviceId]: true }));
    }, 1000);
  };

  const filteredServices = services.filter(service =>
    service.service_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    !(isInstantpaypresnt !== undefined && isInstantpaypresnt !== null && service._id == "206")
  );

  return (
    <Box sx={{ backgroundColor: colors.background, padding: { xs: 2, sm: 3, md: 4 }, borderRadius: '12px' }}>
      {/* Search Input */}
      
      <TextField
        variant="outlined"
        placeholder="Search by service name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />

      <Grid container spacing={2}>
        {filteredServices.map((service) => (
          <Grid 
            item 
            xs={6}
            sm={4}
            md={3}
            lg={2}
            key={service?._id}
          >
            <ServiceItem 
              elevation={0}
              onClick={() => handleNavigation(service?._id)}
              style={{ cursor: routeMap[service?._id] ? 'pointer' : 'default' }}
            >
              <IconWrapper>
                {service.icon ? (
                  loadedImages[service._id] ? (
                    <img 
                      src={`https://api.paypanda.in/api/cloudinary/${service?.icon}`} 
                      alt={service?.service_name}
                      className="ljhdfjdh"
                    />
                  ) : (
                    <img 
                      src={`https://api.paypanda.in/api/cloudinary/${service?.icon}`} 
                      alt={service?.service_name}
                      onLoad={() => handleImageLoad(service._id)}
                      style={{ display: 'none' }}
                    />
                  )
                ) : (
                  getIcon(service?.service_name)
                )}
              </IconWrapper>
              <Typography 
                variant={isXs ? "caption" : "body2"} 
                style={{ 
                  color: colors.text, 
                  fontWeight: 500,
                  fontSize: isXs ? '0.75rem' : '0.875rem',
                  lineHeight: 1.2,
                }}
              >
                {service?.service_name}
              </Typography>
            </ServiceItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ServiceGrid;
